import {
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "./firebase.config";
import { writeUserData } from "./DbServices";
import { validatePassword } from "../utils/authUtils";
import { enqueueSnackbar } from "notistack";

export const getUser = () => {
  if (auth) {
    return auth.currentUser;
  }
};

export const registerUser = async (
  navigate,
  email,
  password,
  confirmPassword
) => {
  if (validatePassword(password, confirmPassword)) {
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        console.log("FIREBASE ACTIONS - USER CREATION SUCCESS", {
          res,
          user: res.user,
        });
        const user = res.user;
        await writeUserData(user.uid, {
          email: user.email,
          name: user.displayName,
          id: user.uid,
          noOfBeds: 10,
          userDetails: user.reloadUserInfo,
        });
        enqueueSnackbar("Successfully Created User");
        navigate("/home");
      })
      .catch((err) => {
        console.log("error while creating user", err);
        enqueueSnackbar(err.message, { variant: "error" });
      });
  }
};
export const onLogin = async (navigate, username, password) => {
  signInWithEmailAndPassword(auth, username, password)
    .then(async (userCredential) => {
      const user = userCredential.user;
      if (user) {
        await writeUserData(user.uid, {
          email: user.email,
          name: user.displayName,
          id: user.uid,
          userDetails: user.reloadUserInfo,
        });
        navigate("/home");
      }
      console.log("FIREBASE ACTIONS - LOGIN ACTIONS SUCCESS", user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("FIREBASE ACTIONS -- LOGIN ERROR", errorCode, errorMessage);
    });
};
export const handleLogout = async (navigate) => {
  await signOut(auth)
    .then(() => {
      navigate("/login");
    })
    .catch((error) => {
      console.log("FIREBASE ACTIONS -- LOGOUT ERROR", error);
    });
};
