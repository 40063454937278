import { isEmpty, equals } from "ramda";

export const validatePassword = (password, confirmPassword) => {
  let isValid = true;
  if (!isEmpty(password) && !isEmpty(confirmPassword)) {
    if (!equals(password, confirmPassword)) {
      isValid = false;
    }
  }
  return isValid;
};
