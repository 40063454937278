import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import Routes from "routes";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider } from "@mui/material";
import { SnackbarUtilsConfigurator } from "components/SnackBarService";

function App() {
  return (
    <StyledEngineProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "right",
          horizontal: "bottom",
        }}
        autoHideDuration={2000}
        preventDuplicate
      >
        {/* <SnackbarUtilsConfigurator /> */}
        <Routes />
      </SnackbarProvider>
    </StyledEngineProvider>
  );
}

export default App;
