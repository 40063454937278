import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Component from "./MinimalLayout.Component";
import {
  getCurrentUser,
  getUserData,
  signOutUser,
} from "../../redux/reducers/commonReducer";

const mapStateToProps = createStructuredSelector({
  userData: getUserData,
});

const mapDispatchToProps = {
  getUser: getCurrentUser,
  signOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
