export const COMMON_ACTION = {
  SET_MODAL_OPEN: "COMMON_ACTION/SET_MODAL_OPEN",

  CREATE_USER_REQUEST: "COMMON_ACTION/CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "COMMON_ACTION/CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "COMMON_ACTION/CREATE_USER_FAILURE",

  LOGOUT_USER_REQUEST: "COMMON_ACTION/LOGOUT_USER_REQUEST",
  LOGOUT_USER_SUCCESS: "COMMON_ACTION/LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAILURE: "COMMON_ACTION/LOGOUT_USER_FAILURE",

  GET_USER_DATA_REQUEST: "COMMON_ACTION/GET_USER_DATA_REQUEST",
  GET_USER_DATA_SUCCESS: "COMMON_ACTION/GET_USER_DATA_SUCCESS",
  GET_USER_DATA_FAILURE: "COMMON_ACTION/GET_USER_DATA_FAILURE",

  GET_USER_BED_DATA_REQUEST: "COMMON_ACTION/GET_USER_BED_DATA_REQUEST",
  GET_USER_BED_DATA_SUCCESS: "COMMON_ACTION/GET_USER_BED_DATA_SUCCESS",
  GET_USER_BED_DATA_FAILURE: "COMMON_ACTION/GET_USER_BED_DATA_FAILURE",

  ADD_BED_DATA_REQUEST: "COMMON_ACTION/ADD_BED_DATA_REQUEST",
  ADD_BED_DATA_SUCCESS: "COMMON_ACTION/ADD_BED_DATA_SUCCESS",
  ADD_BED_DATA_FAILURE: "COMMON_ACTION/ADD_BED_DATA_FAILURE",

  ADD_EXTRA_BED_SUCCESS: "COMMON_ACTION/ADD_EXTRA_BED_SUCCESS",
  ADD_EXTRA_BED_FAILURE: "COMMON_ACTION/ADD_EXTRA_BED_FAILURE",

  UPDATE_USER_BED_DATA_REQUEST: "COMMON_ACTION/UPDATE_USER_BED_DATA_REQUEST",
  UPDATE_USER_BED_DATA_SUCCESS: "COMMON_ACTION/UPDATE_USER_BED_DATA_SUCCESS",
  UPDATE_USER_BED_DATA_FAILURE: "COMMON_ACTION/UPDATE_USER_BED_DATA_FAILURE",

  SET_SUMMARY_MODAL_OPEN: "COMMON_ACTION/SET_SUMMARY_MODAL_OPEN",
  SET_CREATION_MODAL_OPEN: "COMMON_ACTION/SET_CREATION_MODAL_OPEN",
  SET_REASSESS_MODAL_OPEN: "COMMON_ACTION/SET_REASSESS_MODAL_OPEN",

  SET_SELECTED_COT_FOR_CREATION: "COMMON_ACTION/SET_SELECTED_COT_FOR_CREATION",
  CLEAR_SELECTED_COT_FOR_CREATION:
    "COMMON_ACTION/CLEAR_SELECTED_COT_FOR_CREATION",
  SET_SELECTED_COT_FOR_REASSESSMENT:
    "COMMON_ACTION/SET_SELECTED_COT_FOR_REASSESSMENT",
  RESTART_TIMER: "COMMON_ACTION/RESTART_TIMER",
};
