import { ref, onValue, child, push, update, remove } from "firebase/database";
import { database } from "./firebase.config";
import { getUserBedDetails } from "../redux/reducers/commonReducer";
import { COMMON_ACTION as ACTIONS } from "../redux/actions";
import {
  prop,
  values,
  equals,
  map,
  find,
  propEq,
  mergeDeepRight,
  isEmpty,
  assoc,
} from "ramda";
import { enqueueSnackbar } from "notistack";

export async function writeUserData(userId, payload) {
  update(ref(database, "users/" + userId), payload);
}

const generateBedArray = (noOfBeds) => {
  const bedArray = [];
  for (let i = 1; i <= noOfBeds; i++) {
    bedArray.push({
      id: i,
      title: `Bed ${i}`,
      painLevel: "",
      treatment: "",
      isActive: false,
      isReAssess: false,
    });
  }
  return bedArray;
};
export async function getBedData(dispatch, userId, noOfBeds) {
  const userDataRef = ref(database, "users/" + userId + "/bedData");
  console.log("getting bed data =====>", userId, noOfBeds);
  onValue(
    userDataRef,
    (snapshot) => {
      const data = snapshot.val();
      const bedData = values(data);
      const bedArray = generateBedArray(noOfBeds);
      const mergeArray = map((bed) => {
        const matchingItem = find(propEq("title", bed.title))(bedData);
        if (matchingItem) {
          return mergeDeepRight(bed, matchingItem);
        }
        console.log("getting bed data sucess =====>", bed);

        return bed;
      }, bedArray);
      dispatch({
        type: ACTIONS.GET_USER_BED_DATA_SUCCESS,
        response: mergeArray,
      });
    },
    (cancelCallback) => {
      dispatch({
        type: ACTIONS.GET_USER_BED_DATA_FAILURE,
        error: cancelCallback.error,
      });
    }
  );
}

export async function getUserUpdateSnapshot(dispatch, userId) {
  const userDataRef = ref(database, "users/" + userId);
  onValue(
    userDataRef,
    (snapshot) => {
      const data = snapshot.val();
      dispatch({
        type: ACTIONS.GET_USER_DATA_SUCCESS,
        response: data,
      });
      console.log("data", data);
      dispatch(getUserBedDetails());
    },
    (cancelCallback) => {
      console.log("data error", cancelCallback);
      enqueueSnackbar(cancelCallback.error, { variant: "error" });
      dispatch({
        type: ACTIONS.GET_USER_DATA_FAILURE,
        error: cancelCallback.error,
      });
    }
  );
}

export async function getRecentBedData(userId, cotId) {
  return new Promise((resolve, reject) => {
    onValue(
      ref(database, `users/${userId}/bedData/${cotId}`),
      (snapshot) => {
        const data = snapshot.val();
        resolve(data);
      },
      (error) => {
        reject(error);
      },
      {
        onlyOnce: true,
      }
    );
  });
}

export async function addNewBedDetails(userId, payload, reassess, noOfBeds) {
  let newBedKey;
  let postData;
  const selected = prop("selectedValues", payload);
  if (!isEmpty(reassess)) {
    newBedKey = prop("bedId", reassess);
    const recentBed = reassess;
    let data;
    data = assoc("selectedValues", selected, recentBed);
    postData = assoc("isReAssess", true, data);
  } else {
    newBedKey = push(child(ref(database), "bedData")).key;
    postData = {
      bedId: newBedKey,
      ...payload,
    };
  }
  const updates = {};
  updates["users/" + userId + "/bedData/" + newBedKey] = postData;
  return update(ref(database), updates);
}

export async function addExtraBedToUser(userId, noOfBeds) {
  await update(ref(database, "users/" + userId), { noOfBeds: noOfBeds });
}

export async function deleteBedFromUser(userId, noOfBeds) {
  await update(ref(database, "users/" + userId), { noOfBeds });
}

export async function reassessOrClearCot(operation, userId, bedDetails) {
  const bedId = prop("bedId", bedDetails);
  const selectedValue = prop("selectedValues", bedDetails);
  console.log("reassessCot", { bedDetails, bedId, operation, userId });
  if (equals("reassess", operation)) {
    push(
      ref(
        database,
        "users/" + userId + "/bedData/" + bedId + "/reassessmentHistory"
      ),
      selectedValue
    );
    push(
      ref(database, "assessmentHistory/" + userId + "/history/" + bedId),
      selectedValue
    );
  } else {
    remove(ref(database, "users/" + userId + "/bedData/" + bedId));
  }
}
