export const colors = {
  pink: "#ff4586",
  night: "#0C0F0A",

  yellow: "#FBFF12",
  green: "#41EAD4",
  white: "#FFFFFF",

  beige_old: "#FFF5E0",
  beige: "#FAF8F8",
  crimsonRed: "#BB2525",
  lightPink: "#FF6969",
  navyBlue: "#141E46",
  meterBlue: "#628BCE",
  meterGreen: "#4EAC5B",
  meterYellow: "#FFFF54",
  meterRed: "#FF3122",
};
