import React, { lazy } from "react";
import MinimalLayout from "layouts/MinimalLayout";

const LoginScreen = lazy(() => import("screens/LoginPage"));
const HomeScreen = lazy(() => import("screens/HomePage"));

const initalRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/login",
      element: <LoginScreen />,
    },
    {
      path: "/home",
      element: <HomeScreen />,
    },
  ],
};

export default initalRoutes;
