import { Box, AppBar, Toolbar, Typography, Button } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { colors } from "utils/theme";
import { onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import { auth } from "services/firebase.config";
import { enqueueSnackbar } from "notistack";

const MinimalLayout = ({ getUser, signOutUser }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        enqueueSnackbar("User Signed Out", {
          variant: "warning",
        });
        navigate("/login");
        setAuthenticated(false);
      } else {
        enqueueSnackbar("Logged In Successfully", { variant: "success" });
        navigate("/home");
        getUser(user.uid);
        setAuthenticated(true);
      }
    });
  }, []);

  return (
    <Box
      sx={{ backgroundColor: colors.beige, maxHeight: "98vh", height: "98vh" }}
    >
      <AppBar
        position="sticky"
        sx={{
          display: "flex",
          backgroundColor: colors.crimsonRed,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Toolbar>
          {authenticated && (
            <Typography
              sx={{
                fontFamily: "AR One Sans",
                fontSize: 20,
                fontWeight: "800",
              }}
            >
              Pain Assessment Timer
            </Typography>
          )}
        </Toolbar>
        {authenticated && (
          <Button
            sx={{
              color: "white",
              fontFamily: "AR One Sans",
              fontWeight: "800",
            }}
            onClick={() => signOutUser(navigate)}
          >
            Logout
          </Button>
        )}
      </AppBar>
      <Box
        sx={{
          backgroundColor: colors.beige,
          maxHeight: "90vh",
          height: "90vh",
          overflow: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MinimalLayout;
