import { createSelector } from "reselect";
import { prop, equals, isEmpty, path } from "ramda";
import { getUser, handleLogout } from "services/AuthServices";
import {
  reassessOrClearCot,
  getBedData,
  addNewBedDetails,
  addExtraBedToUser,
  deleteBedFromUser,
  getUserUpdateSnapshot,
} from "services/DbServices";
import { enqueueSnackbar } from "notistack";

import { COMMON_ACTION as ACTIONS } from "../actions";

const initialState = {
  summaryModalOpen: false,
  creationModalOpen: false,
  reassessModalOpen: false,

  user: {},
  bedData: [],
  selectedCotData: {},
  reassessmentCotData: {},
  restartTimerData: {},
};

const getSlice = prop("common");

export const getEmail = createSelector(getSlice, prop("email"));

export const getSummaryModalOpen = createSelector(
  getSlice,
  prop("summaryModalOpen")
);
export const getCreationModalOpen = createSelector(
  getSlice,
  prop("creationModalOpen")
);
export const getReassessModalOpen = createSelector(
  getSlice,
  prop("reassessModalOpen")
);

export const getSelectedCotData = createSelector(
  getSlice,
  prop("selectedCotData")
);

export const getReassesmentCotData = createSelector(
  getSlice,
  prop("reassessmentCotData")
);

export const isReassessEnabled = createSelector(
  getSelectedCotData,
  prop("isReAssess")
);

export const getRestartTimerData = createSelector(
  getSlice,
  prop("restartTimerData")
);
export const getBedDetails = createSelector(getSlice, prop("bedData"));
export const getUserData = createSelector(getSlice, prop("user"));

export const getNumberOfBeds = createSelector(getUserData, prop("noOfBeds"));

export const clearSelectedCotData = () => async (dispatch) => {
  dispatch({
    type: ACTIONS.CLEAR_SELECTED_COT_FOR_CREATION,
  });
};
export const setSelectedCotForCreation = (val) => async (dispatch) => {
  dispatch({
    type: ACTIONS.SET_SELECTED_COT_FOR_CREATION,
    payload: val,
  });
};

export const setSelectedCotForReassesment = (val) => async (dispatch) => {
  dispatch({
    type: ACTIONS.SET_SELECTED_COT_FOR_REASSESSMENT,
    payload: val,
  });
};

export const setRestartTimer = (item) => async (dispatch) => {
  dispatch({
    type: ACTIONS.RESTART_TIMER,
    payload: item,
  });
};

export const setSummaryModalOpen = (val) => async (dispatch) => {
  dispatch({
    type: ACTIONS.SET_SUMMARY_MODAL_OPEN,
    summaryModalOpen: val,
  });
};

export const setCreationModalOpen = (val) => async (dispatch) => {
  dispatch({
    type: ACTIONS.SET_CREATION_MODAL_OPEN,
    creationModalOpen: val,
  });
};

export const setReassessModalOpen = (val) => async (dispatch) => {
  dispatch({
    type: ACTIONS.SET_REASSESS_MODAL_OPEN,
    reassessModalOpen: val,
  });
};

export const getCurrentUser = (uid) => async (dispatch) => {
  dispatch({
    type: ACTIONS.GET_USER_DATA_REQUEST,
  });
  try {
    await getUserUpdateSnapshot(dispatch, uid);
  } catch (err) {
    enqueueSnackbar(err, { variant: "error" });
    dispatch({
      type: ACTIONS.GET_USER_DATA_FAILURE,
      error: err,
    });
  }
};

export const getUserBedDetails = () => async (dispatch, getState) => {
  const user = getUser();
  const noOfBeds = getNumberOfBeds(getState()) || 10;
  dispatch({
    type: ACTIONS.GET_USER_BED_DATA_REQUEST,
  });
  try {
    await getBedData(dispatch, user.uid, noOfBeds);
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_USER_BED_DATA_FAILURE,
      error: err,
    });
    enqueueSnackbar(err, { variant: "error" });
  }
};

export const addExtraCot = () => async (dispatch, getState) => {
  const bedData = getBedDetails(getState());
  const user = getUser();

  try {
    const lastBedId = bedData[bedData.length - 1].id;
    const newBedId = lastBedId + 1;

    const newBed = {
      id: newBedId,
      title: `Bed ${newBedId}`,
      painLevel: "",
      treatment: "",
      isActive: false,
      isReAssess: false,
    };
    const updatedCots = [...bedData, newBed];
    console.log("newBedId", newBedId);
    addExtraBedToUser(user.uid, newBedId);
    // dispatch(getCurrentUser(user.uid));
    const newUser = getUserData(getState());
    console.log("newUser", newUser);
    dispatch({
      type: ACTIONS.ADD_EXTRA_BED_SUCCESS,
      payload: updatedCots,
    });
  } catch (err) {
    enqueueSnackbar(err, { variant: "error" });

    dispatch({
      type: ACTIONS.ADD_EXTRA_BED_FAILURE,
      error: err,
    });
  }
};

export const deleteExtraCot = () => async (dispatch, getState) => {
  const bedData = getBedDetails(getState());
  const user = getUserData(getState());
  try {
    const noOfBeds = prop("noOfBeds", user);
    const updatedNoOfBeds = noOfBeds - 1;
    await deleteBedFromUser(user.id, updatedNoOfBeds);
    await getBedData(dispatch, user.id, updatedNoOfBeds);
    enqueueSnackbar("Deleted Cot!", { variant: "success" });
  } catch (err) {
    enqueueSnackbar(err, { variant: "error" });
    dispatch({
      type: ACTIONS.ADD_EXTRA_BED_FAILURE,
      error: err,
    });
  }
};

export const updateBedDetails = (operation, bedDetails) => async (dispatch) => {
  const user = getUser();
  dispatch({
    type: ACTIONS.UPDATE_USER_BED_DATA_REQUEST,
  });
  try {
    console.log("updating details", { operation, bedDetails, user });
    reassessOrClearCot(operation, user.uid, bedDetails);
    dispatch({
      type: ACTIONS.UPDATE_USER_BED_DATA_SUCCESS,
    });
  } catch (err) {
    enqueueSnackbar(err, { variant: "error" });
    dispatch({
      type: ACTIONS.UPDATE_USER_BED_DATA_FAILURE,
      error: err,
    });
  }
};

export const addNewCotDetails =
  (payload, reassess) => async (dispatch, getState) => {
    const user = getUser();
    const noOfBeds = getNumberOfBeds(getState());
    dispatch({
      type: ACTIONS.ADD_BED_DATA_REQUEST,
    });
    try {
      const updated = await addNewBedDetails(
        user.uid,
        payload,
        reassess,
        noOfBeds
      );
      console.log("updated", updated);
      dispatch({
        type: ACTIONS.ADD_BED_DATA_SUCCESS,
        payload: "",
      });
      if (!isEmpty(reassess)) {
        dispatch(setRestartTimer(reassess));
      }
    } catch (err) {
      console.log("ERROR ADDING BED", err);
      enqueueSnackbar(err, { variant: "error" });
      dispatch({
        type: ACTIONS.ADD_BED_DATA_FAILURE,
        error: err,
      });
    }
  };

export const signOutUser = (navigate) => async (dispatch) => {
  dispatch({
    type: ACTIONS.LOGOUT_USER_REQUEST,
  });
  try {
    await handleLogout(navigate);
    dispatch({
      type: ACTIONS.LOGOUT_USER_SUCCESS,
    });
  } catch (err) {
    enqueueSnackbar(err, { variant: "error" });
    dispatch({
      type: ACTIONS.LOGOUT_USER_FAILURE,
      error: err,
    });
  }
};

export default (state = initialState, { type, ...action } = {}) => {
  switch (type) {
    case ACTIONS.SET_SUMMARY_MODAL_OPEN:
      return {
        ...state,
        summaryModalOpen: action.summaryModalOpen,
      };
    case ACTIONS.SET_CREATION_MODAL_OPEN:
      return {
        ...state,
        creationModalOpen: action.creationModalOpen,
      };
    case ACTIONS.ADD_BED_DATA_REQUEST:
    case ACTIONS.ADD_BED_DATA_SUCCESS:
    case ACTIONS.ADD_BED_DATA_FAILURE:
    case ACTIONS.UPDATE_USER_BED_DATA_REQUEST:
    case ACTIONS.UPDATE_USER_BED_DATA_SUCCESS:
    case ACTIONS.UPDATE_USER_BED_DATA_FAILURE: {
      return {
        ...state,
      };
    }
    case ACTIONS.SET_REASSESS_MODAL_OPEN:
      return {
        ...state,
        reassessModalOpen: action.reassessModalOpen,
      };
    case ACTIONS.SET_SELECTED_COT_FOR_CREATION:
      return {
        ...state,
        selectedCotData: action.payload,
      };
    case ACTIONS.SET_SELECTED_COT_FOR_REASSESSMENT:
      return {
        ...state,
        reassessmentCotData: action.payload,
      };
    case ACTIONS.CLEAR_SELECTED_COT_FOR_CREATION:
      return {
        ...state,
        selectedCotData: {},
        reassessmentCotData: {},
      };
    case ACTIONS.RESTART_TIMER:
      return {
        ...state,
        restartTimerData: action.payload,
      };
    case ACTIONS.GET_USER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.response,
      };
    case ACTIONS.GET_USER_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        user: {},
      };
    case ACTIONS.GET_USER_BED_DATA_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ACTIONS.GET_USER_BED_DATA_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        bedData: action.response,
      };
    case ACTIONS.GET_USER_BED_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        bedData: [],
      };
    case ACTIONS.ADD_EXTRA_BED_SUCCESS:
      return {
        ...state,
        bedData: action.payload,
      };
    case ACTIONS.LOGOUT_USER_SUCCESS:
      return initialState;
    default:
      return initialState;
  }
};
