import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";

import commonReducer from "./commonReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    common: commonReducer,
  });
