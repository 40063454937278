import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import logger from "redux-logger";

import createRootReducer from "./reducers/index";

export const history = createBrowserHistory();
const combinedReducer = createRootReducer(history);

// logger
const middlewares = [routerMiddleware(history)];

const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./reducers/index", () =>
    store.replaceReducer(combinedReducer)
  );
}

export default store;
