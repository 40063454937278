// import 'dotenv/config'

import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./redux/store";
import Loader from "components/Loader";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);
